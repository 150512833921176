/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.3.1/dist/jquery.min.js
 * - /npm/jquery.easing@1.4.1/jquery.easing.min.js
 * - /npm/bootstrap@4.2.1/dist/js/bootstrap.min.js
 * - /npm/popper.js@1.14.6/dist/umd/popper.min.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 * - /npm/sweetalert@2.1.2/dist/sweetalert.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
